<template>
    <v-app>
        <HeaderContainer />

        <v-main style="background-color: #ddd;">
            <v-container fluid>
                <v-card
                    class="mx-auto mt-2"
                    max-width="900"
                >
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-img
                                class="mx-2"
                                :src="getUrl(item.imagens)"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card-title style="word-break: keep-all; font-size: 24px; font-family: Anton, Oswald, Impact, Sans;"> {{ item.nome }} </v-card-title>
                            <v-card-title v-if="item.valor_promocional" color="primary">
                                <span>De <s>R$ {{ formatPrice(item.valor_venda) }}</s></span>
                                <span style="font-size: 22px;">
                                    &nbsp;por R$ {{ formatPrice(item.valor_promocional) }}
                                </span>
                            </v-card-title>
                            <v-card-title v-else color="primary" style="font-size: 22px;">
                                <span>R$ {{ formatPrice(item.valor_venda) }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-select
                                    class="mt-2"
                                    v-model="quantidade"
                                    :items="quantidades"
                                    label="Quantidade"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </v-card-text>

                            <v-card-actions style="flex-direction: column;">
                                <v-btn
                                    class="white--text ma-1"
                                    color="primary"
                                    @click="addToCart"
                                    style="width: 240px;"
                                >
                                    Adicionar ao carrinho
                                </v-btn>
                                <v-btn
                                    class="white--text ma-1"
                                    color="primary"
                                    @click="goBack"
                                    text
                                    style="width: 240px;"
                                >
                                    Voltar
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>

                    <v-card-title color="primary">DETALHES DO PRODUTO</v-card-title>
                    <v-card-subtitle style="text-align: left; margin-top: 4px; white-space: pre-line; font-family: Sans, Tahoma; font-size: 16px;">
                        {{ item.descricao }}
                    </v-card-subtitle>
                </v-card>

            </v-container>
        </v-main>

        <FooterContainer />
    </v-app>
</template>

<script>
import FooterContainer from './FooterContainer';
import HeaderContainer from './HeaderContainer';

export default {
    name: 'ItemPage',

    components: {
        FooterContainer,
        HeaderContainer,
    },

    data: () => ({
        loading: {},
        quantidade: 1,
        item: {},
        itemType: null,
        quantidades: [...Array(50).keys()].map(x => x + 1),
    }),

    async mounted() {
        this.itemType = this.$route.path.includes('/produto')
            ? 'produtos'
            : 'pacotes';

        await this.getItem();
    },

    methods: {
        async getItem() {
            const id = this.$route.params.id;

            if (id) {
                await this.$http.get(`ecommerce/${this.itemType}/${id}`).then(resp => {
                    this.item = resp.data;
                });
                return;
            }

            // back to home page
            this.$router.push('/');
        },

        getUrl(imagem) {
            if (!imagem?.url) {
                return '';
            }

            const url = imagem.tmp
                ? `${this.$urlAPI}/files/tmp/${imagem.url}`
                : `${this.$urlAPI}/storage/${imagem.url}`;

            return url;
        },

        addToCart() {
            if (!this.item.id) {
                this.$toast.error('Não foi possivel adicionar, tente novamente');
                return;
            }

            const item = {
                id: this.item.id,
                quantidade: this.quantidade,
                tipo: this.itemType,
            };

            const type = this.itemType === 'produtos'
                ? 'Produto'
                : 'Pacote';

            this.$store.commit('addToCart', item);
            this.$toast.success(`${type} adicionado ao carrinho`);
            this.$router.push('/ecommerce/carrinho');
        },

        goBack() {
            this.$router.push('/');
        },
    },
}
</script>
